.liveblog-container {
    border-radius: 1vh;
    margin-bottom: var(--s1);
    width: 50vw;
    height: 100%;
    max-width: 50vw;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    /* padding: 0.7rem; */
    padding-bottom: 0.8rem;
    overflow: hidden;
}

.button-container {
    width: 100%;
    padding: 3vh 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.liveblog {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}

.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: var(--whiteline-width) solid rgb(236, 236, 236);
    margin-bottom: 1rem;
    padding-bottom: 0.7rem;
}

.footer-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.live_pulsating_text {
    background: rgb(240, 0, 0);
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0.25rem;
    padding: 0.05em 0.25em;
    animation: 1.5s cubic-bezier(0.5, 0, 1, 1) 0s infinite alternate none
        running blinker;
    margin-right: 0.5rem;
}

.fadein {
    animation: fadein 2s;
}

/* Fade in of message */
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Key frames for live pulsating text */
@keyframes blinker {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.last_update {
    font-size: 0.8rem;
}

.timeline {
    display: grid;
    grid-template-columns: 0.15fr var(--whiteline-width) 1fr;
    width: 100%;
}

.timeline__middle {
    position: relative;
    background-color: rgb(236, 236, 236);
}

.timeline__point {
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    top: calc(var(--s0) + var(--s1) / 2);
    border: 2px solid white;
}

.timeline__point--grey {
    background: grey;
}

.timeline__point--red {
    background: red;
}

.timeline__left {
    padding-top: var(--s0);
    padding-right: calc(0.5 * var(--s0) + 0.5rem);
    /* line-height: var(--s0); */
}

.timeline__left_date {
    font-size: 0.7rem;
    height: var(--s1);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.timeline__right_date {
    font-size: 0.7rem;
    height: var(--s1);
    display: none;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0.7rem;
}

.timeline__content {
    display: flex;
    flex-direction: column;
    padding: var(--s0) var(--s0) var(--s0) var(--s1);
    align-items: flex-start;
    /* border-bottom: solid var(--whiteline-width) rgb(236, 236, 236); */
    position: relative;
}

.timeline__content:last-child {
    border-bottom: solid var(--whiteline-width) rgb(236, 236, 236);
}

.timeline__content h2 {
    /* line-height: var(--s0); */
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.3rem;
    height: auto;
    margin-left: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .timeline__content p {
	margin-top: 0;
	margin-bottom: 0.5rem;
	margin-left: 0.5rem;
} */

.timeline__content_message {
    margin-top: 0;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
}

.expand-btn {
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 2px;
}

@media only screen and (min-width: 1920px) {
    .liveblog-container {
        width: 40vw;
        max-width: 40vw;
    }
}

/* If the screen is less than 600px wide, Make the left part of the blog smaller. */
@media (max-width: 1000px) {
    /* Make the left part of the liveblog (left to the point) smaller on smaller devices. */
    .timeline {
        grid-template-columns: 0.1fr var(--whiteline-width) 1fr;
    }

    /* Date text next to timeline is moved from left to right */
    /* .timeline__point::before {
		left: 2rem;
		transform: none;
	} */

    .timeline__right_date {
        display: flex;
    }

    .timeline__left_date {
        display: none;
    }

    /* Lower the liveblog title and message, so the date can go above it, next to the timeline point */
    .timeline__content h2 {
        margin-top: 0.7rem;
        line-height: inherit;
        height: auto;
        margin-left: 0.4rem;
    }

    .liveblog-container {
        width: 95vw;
        max-width: 95vw;
    }
}

/* If screen too small, make scroll bar in liveblog message to prevent overflow */
@media (max-width: 200px) {
    .timeline__content {
        overflow: scroll;
    }
}
