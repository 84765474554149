.center_x {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
}

.main {
    width: 100vw;
    height: 100vh;
}

video,
.fallback-poster {
    width: 100vw;
    height: 100vh;
    left: 50%;
    top: 50%;
    object-fit: cover;
    position: fixed;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.vimeo-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
}

.vimeo-wrapper iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

body {
    overflow-x: hidden;
}