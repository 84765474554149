/* Selects the root element of the document:
   <html> in the case of HTML */
:root {
    --ratio: 1.5;
    --s-5: calc(var(--s-4) / var(--ratio));
    --s-4: calc(var(--s-3) / var(--ratio));
    --s-3: calc(var(--s-2) / var(--ratio));
    --s-2: calc(var(--s-1) / var(--ratio));
    --s-1: calc(var(--s0) / var(--ratio));
    --s0: 1rem;
    --s1: calc(var(--s0) * var(--ratio));
    --s2: calc(var(--s1) * var(--ratio));
    --s3: calc(var(--s2) * var(--ratio));
    --s4: calc(var(--s3) * var(--ratio));
    --s5: calc(var(--s4) * var(--ratio));

    --whiteline-width: 1px;
}

/* Import fonts */
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200&family=Roboto&display=swap');

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'SF Pro Display', 'Roboto', sans-serif !important;
}

a {
    text-decoration: none;
}

svg {
    width: 25%;
    aspect-ratio: 1 / 1;
}

/* If the screen is smaller then 600 px , show 2 buttons next to each other */
@media (max-width: 600px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .big-text {
        font-size: min(4vw, 20px);
    }
}

/* If the screen is smaller then 300 px , show all buttons on top of each other */
@media (max-width: 300px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .small-text {
        font-size: 6vw;
    }

    .big-text {
        font-size: 8vw;
    }
}

/* If the screen is smaller then 200 px , make the logo smaller */
@media (max-width: 200px) {
    .logo {
        min-width: auto;
    }
}
