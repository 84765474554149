.link {
    color: #0191f8;
}

.grid-container {
    display: grid;
    grid-gap: var(--s0);
    grid-template-columns: repeat(3, 1fr);
    width: 50vw;
    max-width: 50vw;
    margin-bottom: var(--s4);
}

.error-message {
    color: white;
    font-size: 1.5rem;
}

.loading {
    color: white;
    font-size: 1.5rem;
}

.fetching {
    text-align: center;
    color: white;
    font-size:24px;
    width: 85vw;
}

@media (max-width: 1000px) {
    .grid-container {
        width: 95vw;
        max-width: 95vw;
    }
}

@media only screen and (min-width: 1920px) {
    .grid-container {
        width: 40vw;
        max-width: 40vw;
    }
}
