.btn {
    box-shadow: 1px 2px 18px 0px rgba(0, 0, 0, 0.15);
    color: black;
    aspect-ratio: 1 / 1;
    border-radius: 1vh;
    transition: transform 0.2s ease-in;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
}

.btn--whatsapp {
    background: #25d366;
    color: #fff;
}

.btn--sos {
    background: #df2525;
    background: linear-gradient(0deg, rgb(194, 86, 85) 0%, rgb(201, 19, 19) 75%, rgb(200, 5, 5) 100%);
    color: #fff;
}

.btn--messenger {
    background: #0085ff;
    color: #fff;
    background: linear-gradient(
        rgb(237, 17, 87) 0%,
        rgb(161, 58, 235) 50%,
        rgb(6, 133, 230) 100%
    );
}

.btn--updates {
    background: rgb(195,53,23);
    background: linear-gradient(180deg, rgba(195,53,23,1) 15%, rgba(204,44,38,1) 28%, rgba(224,41,26,1) 40%, rgba(255,49,0,1) 58%, rgba(255,130,0,1) 80%);
    color: white;
    /* display: none; */
}

.btn--imessage {
    background-color: #fff;
    color: black;
    /* display: none; */
}

.btn--livechat {
    background: rgb(51,191,196);
    background: linear-gradient(0deg, rgba(51,191,196,1) 0%, rgba(9,9,121,1) 75%, rgba(49,40,105,1) 100%);
    color: #fff;
}

.btn--twitter {
    background: black;
    color: #fff;
}

.btn--maps {
    background-color: white;
}

.svg-livecrowd svg{
    width: 40% !important;
}

.btn--instagram {
    color: #fff;
    background-image: linear-gradient(
        180deg,
        hsl(280deg 100% 20%) 0%,
        hsl(306deg 100% 23%) 11%,
        hsl(322deg 100% 31%) 22%,
        hsl(331deg 100% 39%) 33%,
        hsl(346deg 77% 52%) 44%,
        hsl(4deg 95% 62%) 56%,
        hsl(20deg 100% 59%) 67%,
        hsl(35deg 100% 54%) 78%,
        hsl(46deg 100% 50%) 89%,
        hsl(55deg 100% 50%) 100%
    );
}

.btn--snapchat {
    background: yellow;
    color: white;
}

.btn--mail {
    background: #231f20;
    color: #fff;
}

.btn--tiktok {
    color: #fff;
}

.btn--signal {
    color: #fff;
}

.btn--update {
    background: #FFF;
}

.btn--inactive {
    background: grey;
    opacity: 0.5;
    pointer-events: none;
}

.svg-wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-bottom: 40px;
    margin-top: -2px;
    width: 100%;
}

.small-text {
    font-size: min(3vw, 16px);
    margin-left: 0.6rem;
    margin-top: 0.6em;
    margin-bottom: 0.1em;
}

.big-text {
    font-size: min(4vw, 20px);
    margin-left: 0.6rem;
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    font-weight: bold;
}

.text--grey {
    color: rgba(120, 120, 120, 0.8);
}

.text--white {
    color: rgba(255, 255, 255, 0.8);
}

.text--black {
    color: rgba(0, 0, 0);
}

.text-shadow {
    text-shadow: 1px 1px 1px #999;
}

.contact-details {
    position: absolute;
    margin-bottom: 0.6rem;
    bottom: 0;
    left: 0;
}

.svg-wrapper.animate svg {
  animation: rotate 1s ease-in-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.normal-text {
    font-weight: 100;
}